<template>
    <TransportadoraForm v-if="isCadastrar()" :id="id" />
    <TransportadoraList v-if="!isCadastrar()" />    
</template>

<script>
import TransportadoraList from '../../components/cadastro/TransportadoraList.vue';
import TransportadoraForm from '../../components/cadastro/TransportadoraForm.vue';

export default {
    name: 'TransportadoraPage',
    components: { TransportadoraForm, TransportadoraList },

    data() {
        return {
            isCadastro: false,
            isEditar: false,
            id: null
        }
    },

    created() {
        this.validarRouter();
    },

    updated() {
        this.validarRouter();
    },

    methods: {

        editar(id) {
            if (id) {
                this.id = id;
                this.isEditar = true;
            }
            else {
                this.id = null;
                this.isEditar = false;
            }
        },

        isCadastrar() {
            return this.isCadastro || this.isEditar;
        },

        validarRouter() {
            const routerName = this.$route.name
            if (routerName === 'transportadora-cadastro') {
                this.isCadastro = true;
            }
            else if (routerName === 'transportadora-editar') {
                const id = parseInt(this.$route.params.id);
                this.editar(id);
            }
            else {
                this.isCadastro = false;
                this.isEditar = false;
                this.id = null;
            }
        }
    }
}
</script>