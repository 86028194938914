<template>
    <div class="card">
        <div class="flex justify-content-between">
            <div class="flex align-items-center">
                <i class="pi pi-building" style="font-size: 1.4rem"></i>
                <h3 style="margin:0px 5px;">
                    Transportadora
                </h3>
            </div>
        </div>

        <hr />

        <div class="grid p-fluid formgrid mt-3">
            <div class="col-12 sm:col-2 md:col-2 lg:col-2 xl:col-2">
                <div class="field">
                    <label>
                        <strong>Código</strong>
                    </label>
                    <InputText v-model="dados.id" :disabled="true" />
                </div>
            </div>

            <div class="col-12 sm:col-2 md:col-2 lg:col-2 xl:col-2">
                <div class="field">
                    <label>
                        <strong>Código ERP<span style="color:red;">&nbsp;*</span></strong>
                    </label>
                    <InputText v-model="dados.codigoErp" :disabled="integration.disable" />
                </div>
            </div>

            <div class="col-12 sm:col-4 md:col-4 lg:col-4 xl:col-4">
                <div class="field">
                    <label>
                        <strong>Razão Social<span style="color:red;">&nbsp;*</span></strong>
                    </label>
                    <InputText v-model="dados.razaoSocial" class="w-full"
                        :class="{ 'p-invalid': campoInvalido.razaoSocial }" :disabled="integration.disable" />
                </div>
            </div>

            <div class="col-12 sm:col-4 md:col-4 lg:col-4 xl:col-4">
                <div class="field">
                    <label>
                        <strong>Nome Fantasia<span style="color:red;">&nbsp;*</span></strong>
                    </label>
                    <InputText v-model="dados.nomeFantasia" class="w-full"
                        :class="{ 'p-invalid': campoInvalido.nomeFantasia }" :disabled="integration.disable" />
                </div>
            </div>

            <div class="col-12 sm:col-4 md:col-4 lg:col-4 xl:col-6">
                <div class="field">
                    <label>
                        <strong>Endereço<span style="color:red;">&nbsp;*</span></strong>
                    </label>
                    <InputText v-model="dados.endereco" :disabled="integration.disable" />
                </div>
            </div>

            <div class="col-12 sm:col-4 md:col-4 lg:col-4 xl:col-4">
                <div class="field">
                    <label>
                        <strong>CNPJ<span style="color:red;">&nbsp;*</span></strong>
                    </label>
                    <InputMask v-model="dados.cnpj" class="w-full" :class="{ 'p-invalid': campoInvalido.cnpj }"
                        mask="99.999.999/9999-99" :disabled="integration.disable" />
                </div>
            </div>

            <div class="col-12 sm:col-4 md:col-4 lg:col-4 xl:col-2">
                <div class="field">
                    <label>
                        <strong>Inscrição Estadual</strong>
                    </label>
                    <InputText v-model="dados.inscricaoEstadual" :disabled="integration.disable" />
                </div>
            </div>

            <div class="col-12 sm:col-4 md:col-4 lg:col-4 xl:col-4">
                <div class="field">
                    <label>
                        <strong>Bairro<span style="color:red;">&nbsp;*</span></strong>
                    </label>
                    <InputText v-model="dados.bairro" :disabled="integration.disable" />
                </div>
            </div>
            
            <div class="col-12 sm:col-2 md:col-2 lg:col-2 xl:col-2">
                <div class="field">
                    <label>
                        <strong>Código IBGE Município<span style="color:red;">&nbsp;*</span></strong>
                    </label>
                    <InputText v-model="dados.codigoIbgeMunicipio" :disabled="integration.disable" />
                </div>
            </div>
            
            <div class="col-12 sm:col-4 md:col-4 lg:col-4 xl:col-4">
                <div class="field">
                    <label>
                        <strong>Município</strong>
                    </label>
                    <InputText v-model="dados.municipio" :disabled="true" />
                </div>
            </div>

            <div class="col-12 sm:col-2 md:col-2 lg:col-2 xl:col-2">
                <div class="field">
                    <label>
                        <strong>CEP</strong>
                    </label>
                    <InputText v-model="dados.cep" :disabled="integration.disable" />
                </div>
            </div>

            <div class="col-12 sm:col-4 md:col-4 lg:col-4 xl:col-3">
                <div class="field">
                    <label>
                        <strong>Contato</strong>
                    </label>
                    <InputText v-model="dados.contato" :disabled="integration.disable" />
                </div>
            </div>

            <div class="col-12 sm:col-4 md:col-4 lg:col-4 xl:col-3">
                <div class="field">
                    <label>
                        <strong>Telefone</strong>
                    </label>
                    <InputText v-model="dados.telefone" :disabled="integration.disable" />
                </div>
            </div>

            <div class="col-12 sm:col-4 md:col-4 lg:col-4 xl:col-6">
                <div class="field">
                    <label>
                        <strong>E-mail</strong>
                    </label>
                    <InputText v-model="dados.email" :disabled="integration.disable" />
                </div>
            </div>

            <div class="col-12 sm:col-12 md:col-12 lg:col-12 xl:col-12">
                <div class="field">
                    <label>
                        <strong>Observações</strong>
                    </label>
                    <Textarea v-model="dados.observacoes" style="resize: none;" :disabled="integration.disable"></Textarea>
                </div>
            </div>
        </div>

        <hr />

        <div class="flex align-items-center mt-3">
            <Button v-if="integration.visible" type="button" label="Salvar" class="p-button-info mr-1" icon="pi pi-plus" @click="salvar()" :disabled="doubleClick"/>
            <Button v-if="integration.visible" label="Cancelar" icon="pi pi-times" class="p-button p-component p-button-danger mr-2" @click="voltar()" />
            <Button v-if="!integration.visible" label="Voltar" icon="pi pi-spinner" class="p-button-outlined p-button-info mr-2"
                @click="voltar()"></Button>
        </div>

    </div>
</template>

<script>
import ProdutorFiltro from './ProdutorFiltro.vue';
import TransportadoraService from '../../service/TransportadoraService';
import StorageService from '../../service/StorageService';
import IntegracaoService from '../../service/IntegracaoService';

export default {
    name: "TransportadoraForm",
    components: { ProdutorFiltro },

    props: {
        id: {
            type: Number,
            required: false
        },
        isApenasVisulizacao: {
            type: Boolean,
            required: false
        }
    },

    data() {
        return {
            dados: {
                transportadora: {}
            },
            campoInvalido: {},
            integration: {},
            transportadorasSelecionadas: null,
            transportadoraFiltro: []
        }
    },

    methods: {
        voltar() {
            this.$router.push(`/transportadoras`);
        },

        validarCampos() {
            let campos = [];

            if (!this.dados.razaoSocial) {
                campos.push("Razão Social");
                this.campoInvalido.razaoSocial = true;
            }
            else {
                this.campoInvalido.razaoSocial = false;
            }

            if (!this.dados.nomeFantasia) {
                campos.push("Nome Fantasia");
                this.campoInvalido.nomeFantasia = true;
            }
            else {
                this.campoInvalido.nomeFantasia = false;
            }

            if (!this.dados.cnpj) {
                campos.push("CNPJ");
                this.campoInvalido.cnpj = true;
            }
            else {
                this.campoInvalido.cnpj = false;
            }

            if (!this.dados.codigoIbgeMunicipio) {
                campos.push("Código IBGE Municípo");
                this.campoInvalido.codigoIbgeMunicipio = true;
            }
            else {
                this.campoInvalido.codigoIbgeMunicipio = false;
            }

            if (!this.dados.codigoErp) {
                campos.push("Código ERP");
                this.campoInvalido.codigoErp = true;
            }
            else {
                this.campoInvalido.codigoErp = false;
            }

            if (!this.dados.endereco) {
                campos.push("Endereço");
                this.campoInvalido.endereco = true;
            }
            else {
                this.campoInvalido.endereco = false;
            }

            if (!this.dados.bairro) {
                campos.push("Bairro");
                this.campoInvalido.bairro = true;
            }
            else {
                this.campoInvalido.bairro = false;
            }

            if (campos.length > 0) {
                let mensagem = `Por favor, preencher o campo: ${campos[0]}.`;

                if (campos.length > 1) {
                    mensagem = "Por favor, preencher os campos: ";
                    mensagem += campos.join(", ");

                    const indice = mensagem.lastIndexOf(", ");
                    mensagem = `${mensagem.substring(0, indice)} e ${mensagem.substring(indice + 1, mensagem.length)}.`;
                }

                this.$toast.add({
                    severity: 'warn',
                    summary: 'Não foi possível salvar a Transportadora.',
                    detail: mensagem,
                    life: 15000
                });
            }

            return campos.length === 0;

        },

        montarJson() {
            const transportadoraDto = {};
            transportadoraDto["id"] = this.dados.id;
            transportadoraDto["codigoErp"] = this.dados.codigoErp;
            transportadoraDto["razaoSocial"] = this.dados.razaoSocial;
            transportadoraDto["nomeFantasia"] = this.dados.nomeFantasia;
            transportadoraDto["endereco"] = this.dados.endereco;
            transportadoraDto["cnpj"] = this.dados.cnpj;
            transportadoraDto["inscricaoEstadual"] = this.dados.inscricaoEstadual;
            transportadoraDto["bairro"] = this.dados.bairro;
            transportadoraDto["codigoIbgeMunicipio"] = this.dados.codigoIbgeMunicipio;
            transportadoraDto["cep"] = this.dados.cep;
            transportadoraDto["contato"] = this.dados.contato;
            transportadoraDto["telefone"] = this.dados.telefone;
            transportadoraDto["email"] = this.dados.email;
            transportadoraDto["observacoes"] = this.dados.observacoes;
            return transportadoraDto;
        },

        salvar() {
            if (!this.validarCampos()) return;
            const dados = this.montarJson();
            TransportadoraService.salvar(dados)
                .then(({ data }) => {
                    if (!!data) {
                        const detalhe = 'A transportadora código ' + data.transportadora.id +' foi salva com sucesso.';
                        StorageService.setMensagemToast("TRANSPORTADORA", 'success', 'Trasnportadora salva', detalhe, true);
                        this.voltar();
                    }
                })
                .catch(error => {
                    const toast = {
                        severity: 'error',
                        summary: 'Não foi possível salvar a Transportadora.',
                        detail: "Ocorreu uma falha não mapeada, por favor entre em contato com o Administrador!",
                        life: 7500
                    }

                    if (!!error && !!error.response && !!error.response.data) {
                        const { message, exception } = error.response.data;

                        if (exception === "Transportadora não encontrada!") {
                            toast["detail"] = exception;
                        }
                        else {
                            toast["detail"] = message;
                        }
                    }

                    this.$toast.add(toast);
                });
        },

        preencherForm(dto) {
            const data = dto.transportadora;
            const dados = {};
            dados["id"] = data.id;
            dados["codigoErp"] = data.codigoErp;
            dados["razaoSocial"] = data.razaoSocial;
            dados["nomeFantasia"] = data.nomeFantasia;
            dados["endereco"] = data.endereco;
            dados["cnpj"] = data.cnpj;
            dados["inscricaoEstadual"] = data.inscricaoEstadual;
            dados["bairro"] = data.bairro;
            dados["municipio"] = data.municipio.nome;
            dados["codigoIbgeMunicipio"] = data.codigoIbgeMunicipio;
            dados["cep"] = data.cep;
            dados["contato"] = data.contato;
            dados["telefone"] = data.telefone;
            dados["email"] = data.email;
            dados["observacoes"] = data.observacoes;
            this.dados = dados;
        },

        getIntegrationOptons() {
            const paginaAtual = "TRANSPORTADORA";
            IntegracaoService.getIntegrationOptions(paginaAtual)
                .then(({ data }) => {
                    if (data) {
                        this.integration = data;
                    }
                })
                .catch(error => {
                    this.$toast.add({
                        severity: 'info',
                        summary: 'PARÂMETROS DE PÁGINA NÂO DEFINIDOS',
                        detail: 'Você deve cadastrar os parâmetros para esta página no menu lateral em item: Parâmetros de Integração.',
                        life: 10000
                    });
                });
        },
    },

    created() {
        if (this.isApenasVisulizacao) {
            this.integration.visible = false;
        } else {
            this.getIntegrationOptons();
        }
    },

    mounted() {
        if (!!this.id) {
            TransportadoraService.buscarPorId(this.id)
                .then(({ data }) => {
                    this.preencherForm(data);
                })
                .catch(error => {
                    this.dados = {};

                    const toast = {
                        severity: 'error',
                        summary: `Falha ao buscar os dados da transportadora.`,
                        detail: "Ocorreu uma falha não mapeada, por favor entre em contato com o Administrador!",
                        life: 7500
                    }

                    if (!!error && !!error.response && !!error.response.data) {
                        const { message } = error.response.data;

                        if (!!message) {
                            toast["detail"] = message;
                        }
                    }

                    this.$toast.add(toast);
                });
        }
    },
}

</script>